<template>

  <vs-table :data="customersData" @selected="showCustomerDetails"
            :pagination="customersData.length > maxItems ? true : false" :max-items="maxItems" search>
    <template slot="header">

      <div class="d-inline-block float-left">
        <h2 v-if="filter === 'active'">Aktivni uporabniki</h2>
        <h2 v-if="filter === 'inactive'">Neaktivni uporabniki</h2>
        <h2 v-if="filter === 'all'">Vsi uporabniki v izbrani enoti</h2>
      </div>
      <vs-chip class="mt-1 ml-3">{{ customersData.length }}</vs-chip>

    </template>
    <template slot="thead">
      <vs-th>
        Aktivnosti
      </vs-th>
      <vs-th sort-key="firstName">
        Ime
      </vs-th>
      <vs-th sort-key="lastName">
        Priimek
      </vs-th>
      <vs-th sort-key="address">
        Naslov
      </vs-th>
      <vs-th sort-key="createdAt">
        Uporabnik od
      </vs-th>
      <vs-th>
        Opomba
      </vs-th>
      <vs-th v-if="isUnitOne">
        Uporabnik aplikacije
      </vs-th>
      <vs-th>
        Akcije
      </vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :key="'active_' + index" v-for="(tr, index) in data" v-if="data[index].disabled === 0" :data="tr">

        <vs-td :data="data[index].profile_image">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

            <div class="avatarWithStatus">
              <vs-avatar size="60px" :src="data[index].gender === 'male' ? sir : madam"
                         :color="data[index].gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
              <vs-avatar v-if="data[index].active === 1" size="small" class="status" color="success"
                         icon="check_circle"/>
              <vs-avatar v-if="data[index].active === 0" size="small" class="status" color="danger"
                         icon="radio_button_unchecked"/>
            </div>

          </vs-col>
        </vs-td>

        <vs-td :data="data[index].firstName">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{ data[index].firstName }}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].lastName">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{ data[index].lastName }}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].address">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{ data[index].address ? data[index].address.quickSearch : '' }}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].createdAt">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{ moment(data[index].createdAt).format('DD. MM. YYYY') }}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].notes">

          <vs-tooltip :text="data[index].notes" title="Opomba" :key="data[index].id"
                      v-if="data[index].notes" position="left">
            <vs-avatar icon="note"/>
            <span>&nbsp;</span>
          </vs-tooltip>
          <div v-else>
            <em>/</em>
          </div>

        </vs-td>

        <vs-td v-if="isUnitOne">

          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" v-if="data[index].user">
            <vs-avatar size="small" :icon="'done'"
                       :style="'background-color: #28c76f'"/>

            <vs-button size="small" color="grey" icon="edit"
                       @click.stop="() => { $router.push({name: 'CustomerAsMobileAppUserRegistration', params: {customer: data[index]}}) }">
              Uredi podatke
            </vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12" v-if="!data[index].user">

            <vs-avatar size="small" :icon="'close'"
                       :style="'background-color: #c3c3c3'"/>

            <vs-button size="small" color="grey" icon="add"
                       @click.stop="() => { $router.push({name: 'CustomerAsMobileAppUserRegistration', params: {customer: data[index]}}) }">
              Registriraj
            </vs-button>
          </vs-col>

        </vs-td>

        <vs-td :data="data[index]" @mousedown.stop="stopTheEvent">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

            <vs-button color="primary" icon="edit"
                       @click.stop="() => { $router.push({name: 'Uporabnik', params: {customerId: data[index].id}}) }">
              Uredi
            </vs-button>

          </vs-col>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>

</template>

<script>
import Vue from 'vue';
import sir from '../../../assets/images/portrait/sir.svg'
import madam from '../../../assets/images/portrait/madam.svg'


export default {
  name: 'customers-table',
  props: {
    customersData: Array,
    filter: String
  },
  components: {},

  data() {
    return {
      maxItems: 500,
      sir: sir,
      madam: madam
    }
  },

  computed: {
    isUnitOne(){
      const selectedUnit = localStorage.getItem('selectedUnit') ? JSON.parse(localStorage.getItem('selectedUnit')) : JSON.parse(localStorage.getItem('userInfo')).units[0];
      const condition1 = selectedUnit.id === 1;
      // const condition2 = JSON.parse(localStorage.getItem('userInfo')).role === 1;
      return condition1
    }
  },

  mounted() {
    const _this = this;

  },

  destroyed() {
    console.log("destroyed");
    const _this = this;

  },

  beforeDestroy() {
    console.log("beforeDestroy");
    const _this = this;


  },

  methods: {

    showCustomerDetails(tr) {
      const _this = this;

      _this.$emit('showCustomerDetails', tr);
    },

    getUnitDataById(unitId) {
      const _this = this;

      if (localStorage.getItem('userInfo')) {
        let unit = _.filter(JSON.parse(localStorage.getItem('userInfo')).units, {id: unitId})[0];
        if (unit) {
          return unit.name + ' (' + unit.coveredMunicipalities + ')';
        } else
          return 'podatka ni bilo mogoče pridobiti';
      }

    },

    stopTheEvent: (event) => {
      event.stopPropagation();
      event.preventDefault();
    },


  }
}
</script>

